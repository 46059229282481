<template>
  <ion-page>
    <Main>
      <template #subHeader>
        <ion-toolbar>
          <ion-row v-if="order" class="ion-padding-p ion-padding-vertical">
            <ion-col size="1" class="ion-no-padding">
              <span>
                <ion-icon
                  @click="back"
                  class="back-arrow"
                  :icon="arrowBack"
                ></ion-icon>
              </span>
            </ion-col>
            <ion-col class="ion-no-padding">
              <ion-row class="ion-no-padding">
                <ion-col size="12" class="ion-no-padding">
                  <p class="title">
                    {{ product.name }} {{ $filters.folio(product.id) }}
                  </p>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </template>
      <template #content>
        <ion-row class="cards-container ion-padding-p  ion-padding-vertical">
          <ion-col class="ion-no-padding">
            <ion-card class="ion-no-padding ion-no-margin">
              <ion-card-content>
                <ion-row class="col-title">
                  <ion-col size="12" class="ion-no-padding">
                    <p class="title ion-padding-horizontal">
                      Ingrese el numero de etiqueta
                    </p>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="ion-no-padding">
                    <ion-item>
                      <ion-input v-model="product.label"></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row class="col-title">
                  <ion-col size="12" class="ion-no-padding">
                    <p class="title ion-padding-horizontal">
                      Comentarios del Artículo
                    </p>
                  </ion-col>
                </ion-row>
                <ion-row class="ion-padding-horizontal">
                  <ion-col>
                    <ion-label>Agregue un comentario</ion-label>
                    <ion-textarea
                      v-model="product.comment"
                      rows="4"
                      auto-grow="true"
                    ></ion-textarea>
                  </ion-col>
                </ion-row>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </template>
      <template #subFooter>
        <ion-toolbar>
          <ion-row v-if="order" class="ion-padding-p ion-padding-vertical">
            <ion-col size="12" class="ion-no-padding">
              <ion-button
                type="button"
                class="ion-margin-bottom"
                expand="block"
                color="primary"
                @click="confirm"
              >
                Editar Registro
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </template>
    </Main>
  </ion-page>
</template>

<script>
import useOrder from "@/use/useOrder";
import {
  IonPage,
  IonRow,
  IonCol,
  IonIcon,
  IonCard,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonToolbar,
  IonButton,
  IonCardContent,
  alertController,
} from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import useApi from "@/use/useApi";
import { useRoute, useRouter } from "vue-router";
import Main from "@/components/system/main.vue";
import { watch, ref } from "vue";

export default {
  name: "Order detail",
  components: {
    Main,
    IonPage,
    IonRow,
    IonCol,
    IonCard,
    IonItem,
    IonInput,
    IonLabel,
    IonTextarea,
    IonToolbar,
    IonButton,
    IonCardContent,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    const { params } = useRoute();
    const { order, getOrder } = useOrder();
    const product = ref({});

    function getProduct() {
      if (order.value.products) {
        const prod = Object.values(order.value.products).find((prod) =>
          prod.done_p.find((pr) => pr.id == params.product)
        );
        const art = prod.done_p.find((pr) => pr.id == params.product);
        product.value = prod ? prod : {};
        product.value.comment = art.comment;
      }
    }

    watch(() => order.value, getProduct);
    watch(() => params.product, getProduct);

    if (!order.value) {
      getOrder(params.id, "armador");
    } else if (Object.keys(order.value).length === 0) {
      getOrder(params.id, "armador");
    } else {
      getProduct();
    }

    async function back() {
      //router.push("/armador/orders/" + params.id + "/");
      getOrder(params.id, "armador");
      router.back();
    }

    const { post } = useApi();
    async function confirm() {
      const data = await post(
        "app/armador/edit_product",
        {
          id: params.id,
          product: params.product,
          comment: product.value.comment,
          label: product.value.label,
        },
        "Validando..."
      );
      if (data === null) {
        const alert = await alertController.create({
          class: "warning",
          header: "Error",
          message: "Se genero un error en servidor",
        });
        await alert.present();
      } else {
        if (data.error === false) {
          const alert = await alertController.create({
            class: "success",
            header: "Exito!",
            message: data.message,
          });
          await alert.present();
          const done = await alert.onDidDismiss();
          if (done) {
            back();
          }
        }
      }
    }
    return {
      order,
      product,
      arrowBack,
      back,
      confirm,
    };
  },
};
</script>

<style>
ion-card {
  border-radius: 15px;
  border: 1px solid var(--ion-color-primary);
  box-shadow: none;
  font-size: 12px;
}
ion-card-content.card-content-md {
  padding-left: 4px;
  padding-right: 4px;
}
ion-card-title {
  color: var(--ion-color-medium);
  font-size: 14px;;
}
ion-toolbar {
  --background: var(--ion-color-light);
}
ion-button::part(native) {
  text-transform: none;
  font-size: 15px;
}
ion-textarea {
  border: 2px solid var(--ion-color-light);
  border-radius: 8px;
}
.col-title {
  background: var(--ion-color-light);
}
.title.ion-padding-horizontal {
  font-size: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: var(--ion-color-dark);
}
</style>
